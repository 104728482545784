import React, { useEffect, useState } from 'react';
import {
  EmailAuthProvider,
  getAuth,
  multiFactor,
  reauthenticateWithCredential,
  TotpMultiFactorGenerator,
  TotpSecret,
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/joy/Typography';
import { Button, FormControl, FormHelperText, FormLabel, Input, Sheet } from '@mui/joy';
import { InfoOutlined } from '@mui/icons-material';
import QRCode from 'react-qr-code';
import Stack from '@mui/joy/Stack';
import Box from '@mui/joy/Box';

export default function TwoFactorPage() {
  const [totpSecret, setTotpSecret] = useState<TotpSecret | null>(null);
  const [totpUri, setTotpUri] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showCode, setShowCode] = useState<boolean>(false);
  const [totpCode, setTotpCode] = useState<string>('');
  const [mfaDisplayName, setMfaDisplayName] = useState<string>('Venley TOTP');

  const navigate = useNavigate();

  useEffect(() => {
    return getAuth().onAuthStateChanged(async user => {
      if (user) {
        setEmail(user.email!);
      } else {
        return navigate('/sign-in');
      }
    });
  }, []);

  async function enableTwoFactor() {
    const user = getAuth().currentUser;
    if (!user) {
      return navigate('/sign-in');
    }

    if (password.length < 8) {
      return setError('La contraseña debe tener al menos 8 caracteres');
    }

    setError(null);
    setLoading(true);

    try {
      await reauthenticateWithCredential(user, EmailAuthProvider.credential(user.email!, password));

      const multiFactorSession = await multiFactor(user).getSession();
      const totpSecret = await TotpMultiFactorGenerator.generateSecret(multiFactorSession);
      const totpUri = totpSecret.generateQrCodeUrl(user.email!, 'managerapp (Venley)');

      setTotpSecret(totpSecret);
      setTotpUri(totpUri);
    } catch (e: any) {
      if (e.code === 'auth/invalid-credential') {
        return setError('La contraseña actual es incorrecta');
      }

      if (e.code === 'auth/unverified-email') {
        return navigate('/app/settings');
      }
    } finally {
      setLoading(false);
    }
  }

  async function testTotpCode() {
    const user = getAuth().currentUser;

    if (!user) {
      return navigate('/sign-in');
    }

    if (totpCode.length !== 6) {
      return setError('El código ingresado debe tener 6 dígitos');
    }

    if (mfaDisplayName.trim().length < 1) {
      return setError('Ingresa un nombre válido para la app de autenticación');
    }

    setError(null);
    setLoading(true);

    try {
      const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(totpSecret!, totpCode);

      await multiFactor(user).enroll(multiFactorAssertion, mfaDisplayName);

      navigate('/app');
    } catch (e: any) {
      if (e.code === 'auth/invalid-verification-code') {
        setError('Código incorrecto. Por favor, verifica y vuelve a intentarlo.');
      } else {
        if (e.response && e.response.data) {
          console.error('Detalles de la respuesta:', e.response.data);
        }
        setError('Error en la inscripción de 2FA. Por favor, inténtalo de nuevo.');
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Sheet
      sx={{
        width: 400,
        mx: 'auto', // margin left & right
        my: 4, // margin top & bottom
        py: 3, // padding top & bottom
        px: 2, // padding left & right
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRadius: 'sm',
        boxShadow: 'md',
      }}>
      <Box sx={{ mb: 4 }}>
        <Typography level="h4" component="h1">
          Configura tu autenticación de dos factores
        </Typography>
        <Typography level="body-sm">Paso requerido para continuar.</Typography>
      </Box>

      <Input
        name="email"
        type="email"
        value={email}
        sx={{
          display: {
            xs: 'none',
          },
        }}
      />

      {totpSecret && (
        <Stack direction="column" alignItems="center" spacing={3}>
          <QRCode value={totpUri} />

          <Typography level="body-sm" fontWeight="bold">
            Escanea el código QR con tu app de autenticación para completar la configuración.
          </Typography>

          <Button variant="plain" onClick={() => setShowCode(!showCode)}>
            {showCode ? 'Ocultar' : 'Mostrar'} código para ingreso manual
          </Button>

          {showCode && (
            <Typography level="body-sm" fontWeight="bold">
              {totpSecret.secretKey}
            </Typography>
          )}

          <FormControl error={error !== null}>
            <FormLabel>Código de 6 digitos</FormLabel>

            <Input
              name="totpCode"
              type="text"
              placeholder="Ingresa el código aquí"
              required
              onChange={e => setTotpCode(e.target.value)}
            />

            <FormHelperText>
              Ingresa el código que genera tu app de autenticación para verificar que todo funciona correctamente.
            </FormHelperText>
          </FormControl>

          <FormControl error={error !== null}>
            <FormLabel>Nombre de la aplicación</FormLabel>
            <Input
              name="mfaName"
              type="text"
              placeholder="Ingresa el nombre de la app de autenticación"
              required
              onChange={e => setMfaDisplayName(e.target.value)}
            />
            <FormHelperText>
              Ingresa el nombre de la app de autenticación que estás usando para que puedas identificarla en el futuro.
            </FormHelperText>

            {error && (
              <FormHelperText>
                <Typography color="danger" startDecorator={<InfoOutlined />}>
                  {error}
                </Typography>
              </FormHelperText>
            )}
          </FormControl>
        </Stack>
      )}

      {!totpSecret && (
        <FormControl error={error !== null}>
          <FormLabel>Contraseña</FormLabel>
          <Input
            name="password"
            type="password"
            placeholder="Ingresa tu contraseña actual"
            required
            onChange={e => setPassword(e.target.value)}
          />

          <FormHelperText>
            <Typography color="danger">
              {error !== null ? (
                <>
                  <InfoOutlined /> {error}
                </>
              ) : null}
            </Typography>
          </FormHelperText>
        </FormControl>
      )}

      <Button sx={{ mt: 1 }} onClick={totpSecret ? testTotpCode : enableTwoFactor} loading={loading}>
        {totpSecret ? 'Verificar código' : 'Habilitar autenticación de dos factores'}
      </Button>
    </Sheet>
  );
}
