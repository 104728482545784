interface GazetteInterface {
  uuid: string;
  jurisdiction: string;
  type: string;
  publicationDate: string;
  gazetteNumber: number;
  fileUrl: string;
  createdAt: string;
  updatedAt: string;
}

export class Gazette {
  constructor(gazette: GazetteInterface) {
    this.uuid = gazette.uuid;
    this.jurisdiction = gazette.jurisdiction;
    this.type = gazette.type;
    this.fileUrl = gazette.fileUrl;
    this.gazetteNumber = gazette.gazetteNumber;
    this.publicationDate = new Date(gazette.publicationDate);
    this.createdAt = new Date(gazette.createdAt);
    this.updatedAt = new Date(gazette.updatedAt);
  }

  uuid: string;
  jurisdiction?: string;
  type: string;
  publicationDate: Date;
  fileUrl: string;
  gazetteNumber: number;
  createdAt: Date;
  updatedAt: Date;
}
