import { createBrowserRouter } from 'react-router-dom';
import Error404NotFound from './presentation/Error404NotFound';
import SignInPage from '../auth/sign_in/presentation/SignInPage';
import React from 'react';
import ForgotPasswordPage from '../auth/forgot_password/presentation/ForgotPasswordPage';
import DashboardPage from '../app/dashboard/DashboardPage';
import App from '../app/App';
import AuthWrapper from '../auth/presentation/AuthWrapper';
import WelcomePage from '../welcome/presentation/WelcomePage';
import UsersListPage from '../users/presentation/list/UsersListPage';
import UserCreatePage from '../users/presentation/form/UserCreatePage';
import UserDetailsPage from '../users/presentation/details/UserDetailsPage';
import RoleFormPage from '../roles/form/RoleFormPage';
import RolesListPage from '../roles/list/RolesListPage';
import TwoFactorPage from '../auth/two_factor/presentation/TwoFactorPage';
import ApiPermission from './domain/api-permissions';
import { PermissionsWrapper } from '../auth/presentation/PermissionsWrapper';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import { EmailListPage } from '../emails/presentation/EmailListPage';
import SettingsPage from './profile/presentation/SettingsPage';
import { GazetteListPage } from '../gazettes/list/presentation/GazetteListPage';
import { GazetteListManagementProvider } from '../gazettes/list/application/GazetteListPageContext';
import GazetteFormPage from '../gazettes/form/presentation/GazetteFormPage';
import TicketChangePage from '../tickets/components/TicketChangePage';
import TicketConsultationPage from '../tickets/components/TicketConsultationPage';
import TiketsPage from '../tickets/components/TiketsPage';

export const routes = [
  {
    path: '/',
    element: <WelcomePage />,
    errorElement: <Error404NotFound />,
  },
  {
    path: '/sign-in',
    element: <SignInPage />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPasswordPage />,
  },
  {
    path: '/two-factor',
    element: <TwoFactorPage />,
  },
  {
    path: '/app',
    element: (
      <AuthWrapper>
        <PermissionsWrapper>
          <App />
        </PermissionsWrapper>
      </AuthWrapper>
    ),
    handle: {
      crumb: <HomeRoundedIcon />,
    },
    children: [
      {
        path: '/app',
        element: <DashboardPage />,
        handle: {
          crumb: 'Inicio',
        },
      },
      {
        path: '/app/settings',
        element: <SettingsPage />,
        handle: {
          crumb: 'Configuración',
        },
      },
      {
        path: '/app/emails',
        element: <EmailListPage />,
        handle: {
          crumb: 'Correos enviados',
          permission: ApiPermission.GetEmails,
        },
      },
      {
        path: '/app/tickets',
        handle: {
          crumb: 'Tickets',
        },
        children: [
          {
            path: '/app/tickets',
            element: <TiketsPage />,
            handle: {
              crumb: 'Tickets',
            },
          },
          {
            path: '/app/tickets/change',
            element: <TicketChangePage />,
            handle: {
              crumb: 'Ticket de cambio',
            },
          },
          {
            path: '/app/tickets/consultation',
            element: <TicketConsultationPage />,
            handle: {
              crumb: 'Ticket de consulta',
            },
          },
        ],
      },
      {
        path: '/app/users',
        handle: {
          crumb: 'Usuarios',
          permission: ApiPermission.GetUsers,
        },
        children: [
          {
            path: '/app/users',
            element: <UsersListPage />,
            handle: {
              crumb: 'Lista de usuarios',
            },
          },
          {
            path: '/app/users/form',
            element: <UserCreatePage />,
            handle: {
              crumb: 'Crear usuario',
              permission: ApiPermission.CreateUsers,
            },
          },
          {
            path: '/app/users/:id/edit',
            element: <UserCreatePage />,
            handle: {
              crumb: 'Editar usuario',
              permission: ApiPermission.UpdateUsers,
            },
          },
          {
            path: '/app/users/:userId',
            element: <UserDetailsPage />,
            handle: {
              crumb: 'Detalles de usuario',
              permission: ApiPermission.GetUsers,
            },
          },
        ],
      },
      {
        path: '/app/roles',
        handle: {
          crumb: 'Roles y permisos',
          permission: ApiPermission.GetRoles,
        },
        children: [
          {
            path: '/app/roles',
            element: <RolesListPage />,
            handle: {
              crumb: 'Lista de roles',
            },
          },
          {
            path: '/app/roles/form',
            element: <RoleFormPage />,
            handle: {
              crumb: 'Crear rol',
              permission: ApiPermission.CreateRoles,
            },
          },
          {
            path: '/app/roles/:id/edit',
            element: <RoleFormPage />,
            handle: {
              crumb: 'Editar rol',
              permission: ApiPermission.UpdateRoles,
            },
          },
        ],
      },
      {
        path: '/app/gazettes',
        handle: {
          crumb: 'Gacetas',
          permission: ApiPermission.GetGazettes,
        },
        children: [
          {
            path: '/app/gazettes',
            element: (
              <GazetteListManagementProvider>
                <GazetteListPage />
              </GazetteListManagementProvider>
            ),
            handle: {
              crumb: 'Lista de gacetas',
            },
          },
          {
            path: '/app/gazettes/form',
            element: <GazetteFormPage />,
            handle: {
              crumb: 'Subir gaceta',
              permission: ApiPermission.CreateGazettes,
            },
          },
          {
            path: '/app/gazettes/:id/edit',
            element: <h1>GazetteForm (edit)</h1>,
            handle: {
              crumb: 'Editar gaceta',
              permission: ApiPermission.UpdateGazettes,
            },
          },
        ],
      },
      {
        path: '*',
        element: <Error404NotFound />,
      },
    ],
  },
];
export const router = createBrowserRouter(routes);
