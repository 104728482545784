import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import { Button, FormControl, FormHelperText, FormLabel, Input, Link, Snackbar } from '@mui/joy';
import { app } from '../../../index';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { InfoOutlined } from '@mui/icons-material';

export default function ForgotPasswordPage() {
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const [sentEmail, setSentEmail] = React.useState(false);

  const navigate = useNavigate();

  const onCloseSnackbar = () => {
    setOpen(false);
  };

  async function sendResetPasswordEmail(formData: any) {
    const { email } = formData;

    const auth = getAuth(app);

    setError(null);
    setLoading(true);
    setSentEmail(false);

    try {
      await sendPasswordResetEmail(auth, email);
      setOpen(true);
      setSentEmail(true);
    } catch (e: any) {
      console.error(e?.code);
      if (e?.code) {
        switch (e.code) {
          case 'auth/invalid-email':
            setError('El email no es válido');
            break;
          case 'auth/user-disabled':
          case 'auth/user-not-found':
            setError('Cuenta no existente o deshabilitado');
            break;
          case 'auth/wrong-password':
          case 'auth/too-many-requests':
            setError('Demasiados intentos de iniciar sesión. Intenta de nuevo más tarde');
            break;
        }
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <form
        onSubmit={e => {
          e.preventDefault();
          if (!e.currentTarget.checkValidity()) {
            e.stopPropagation();
            return;
          }

          const formData = new FormData(e.currentTarget);
          const formJson = Object.fromEntries(formData.entries());
          sendResetPasswordEmail(formJson);
        }}>
        <Sheet
          sx={{
            width: 400,
            mx: 'auto', // margin left & right
            my: 4, // margin top & bottom
            py: 3, // padding top & bottom
            px: 2, // padding left & right
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            borderRadius: 'sm',
            boxShadow: 'md',
          }}>
          <div>
            <Typography level="h4" component="h1">
              ¡Bienvenido!
            </Typography>
            <Typography level="body-sm">Inicia sesión para continuar.</Typography>
          </div>

          <FormControl error={error !== null}>
            <FormLabel>Email</FormLabel>
            <Input name="email" type="email" placeholder="ejemplo@venley.io" required />

            <FormHelperText>
              {error !== null ? (
                <>
                  <InfoOutlined /> {error}
                </>
              ) : null}
            </FormHelperText>
          </FormControl>

          <FormHelperText>
            {sentEmail ? (
              <>
                <InfoOutlined /> Se ha enviado un correo electrónico con instrucciones para restablecer tu contraseña.
                Por favor revisa tu correo.
              </>
            ) : null}
          </FormHelperText>

          <Button sx={{ mt: 1 }} type="submit" loading={loading}>
            Restablecer contraseña
          </Button>

          <Link sx={{ alignSelf: 'center' }} onClick={() => navigate(-1)}>
            Volver
          </Link>
        </Sheet>
      </form>

      <Snackbar
        autoHideDuration={5000}
        color="success"
        variant="solid"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={onCloseSnackbar}>
        Se ha enviado un correo electrónico con instrucciones para restablecer tu contraseña.
      </Snackbar>
    </>
  );
}
