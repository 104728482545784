import React from 'react';
import Grid from '@mui/joy/Grid';
import Box from '@mui/joy/Box';

export default function CustomBox() {
  return (
    <Grid xs={12} sm={12} md={12} style={{ paddingBottom: '4%' }}>
      <Box
        className="custom-box"
        sx={{
          height: '265px',
          borderRadius: '10px',
          border: '1px solid #9A9AA9',
          fontFamily: 'Maison Neue',
        }}>
        {/* Contenido del Box */}
      </Box>
    </Grid>
  );
}
