import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import '@fontsource/inter';
import { RouterProvider } from 'react-router-dom';

// Importa la función de configuración de i18n
import './i18n';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { router } from './core/router';
import { CssVarsProvider } from '@mui/joy/styles';
import { CssBaseline } from '@mui/joy';
import { RecoilRoot } from 'recoil';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyD-ptbCtV9uAsf21wS82iRggMG7OUqrB24',
  authDomain: 'venley-52e14.firebaseapp.com',
  projectId: 'venley-52e14',
  storageBucket: 'venley-52e14.appspot.com',
  messagingSenderId: '1091805405006',
  appId: '1:1091805405006:web:e6bd14cedd5fedaa85481f',
  measurementId: 'G-KGX4YKXRX0',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <CssVarsProvider>
      <CssBaseline />
      <RecoilRoot>
        <RouterProvider router={router} />
      </RecoilRoot>
    </CssVarsProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
