export function convertRolePermissionToResource(resource: string): string {
  switch (resource) {
    case 'users':
      return 'usuarios';

    case 'roles':
      return 'roles';
    default:
      return resource;
  }
}

export function convertRolePermissionToAction(permission: string): string {
  const [resource, action] = permission.split(':');

  const resourceName = convertRolePermissionToResource(resource);
  let actionName = action;

  switch (action) {
    case 'get':
      actionName = 'Ver';
      break;
    case 'create':
      actionName = 'Crear';
      break;
    case 'update':
      actionName = 'Actualizar';
      break;
    case 'delete':
      actionName = 'Eliminar';
      break;
    case 'resend':
      actionName = 'Reenviar';
      break;
  }

  return `${actionName} ${resourceName}`;
}
