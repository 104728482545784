import React, { useEffect } from 'react';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import Table from '@mui/joy/Table';
import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { useTranslation } from 'react-i18next';
import { Role } from '../role.interface';
import http from '../../core/infrastructure/HttpService';
import { Chip, DialogActions, DialogContent, DialogTitle, Grid, Modal, ModalDialog, Snackbar } from '@mui/joy';
import { convertRolePermissionToAction } from '../role-utils';
import Divider from '@mui/joy/Divider';
import DeleteForever from '@mui/icons-material/DeleteForever';
import { Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export default function RolesListPage() {
  const [roles, setRoles] = React.useState<Role[]>([]);
  const [displayDeleteModal, setDisplayDeleteModal] = React.useState(false);
  const [roleToDelete, setRoleToDelete] = React.useState<Role | null>(null);
  const [showDeletionError, setShowDeletionError] = React.useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    async function fetchRoles() {
      try {
        const response = await http.get('/roles');
        setRoles(response.data);
      } catch (e) {
        console.log(e);
      }
    }

    fetchRoles();
  }, []);

  async function deleteRole() {
    try {
      await http.delete(`/roles/${roleToDelete?.uuid}`);
      setRoles(roles.filter(role => role.uuid !== roleToDelete?.uuid));
      setDisplayDeleteModal(false);
      setRoleToDelete(null);
    } catch (e) {
      console.log(e);
      setShowDeletionError(true);
    }
  }

  return (
    <React.Fragment>
      <Snackbar
        autoHideDuration={3000}
        color="danger"
        variant="solid"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showDeletionError}
        onClose={() => setShowDeletionError(false)}>
        {t('roles.error_eliminar_rol')}
      </Snackbar>
      <Modal open={displayDeleteModal} onClose={() => setDisplayDeleteModal(false)}>
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRoundedIcon />
            {t('roles.confirmacion')}
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Typography>
              {t('roles.eliminar_rol')} <b>{roleToDelete?.name}</b>?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="solid" color="danger" onClick={deleteRole}>
              {t('roles.eliminar')}
            </Button>
            <Button
              variant="plain"
              color="neutral"
              onClick={() => {
                setDisplayDeleteModal(false);
                setRoleToDelete(null);
              }}>
              {t('roles.cancelar')}
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>

      <Sheet
        sx={{
          mx: 'auto',
          my: 4,
          py: 3,
          px: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          borderRadius: 'sm',
          boxShadow: 'md',
        }}>
        <Typography level="h2">{t('roles.roles')}</Typography>

        <Table stripe="odd" sx={{ width: '100%' }}>
          <thead>
            <tr>
              <th style={{ width: '20%' }}>{t('roles.nombre')}</th>
              <th style={{ width: '40%', textAlign: 'center' }}>{t('roles.permisos')}</th>
              <th style={{ width: '40%', textAlign: 'center' }}>{t('roles.acciones')}</th>
            </tr>
          </thead>
          <tbody>
            {roles.map(role => (
              <tr key={role.uuid}>
                <td style={{ wordWrap: 'break-word' }}>{role.name}</td>
                <td>
                  <Stack direction="row" spacing={1} justifyContent="center" sx={{ flexWrap: 'wrap' }}>
                    {role.permissions.map(permission => (
                      <Chip key={`${role.uuid}${permission}`} variant="soft" color="primary">
                        {convertRolePermissionToAction(permission)}
                      </Chip>
                    ))}
                  </Stack>
                </td>
                <td style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  <Stack direction={{ xs: 'column', md: 'row' }} spacing={1} justifyContent="center">
                    <Button
                      size="sm"
                      variant="solid"
                      startDecorator={<Edit />}
                      sx={{
                        width: { xs: '100%', md: 'auto' },
                        marginRight: { md: 1 },
                      }}
                      onClick={() => navigate(`/app/roles/${role.uuid}/edit`)}>
                      {t('roles.editar')}
                    </Button>
                    <Button
                      size="sm"
                      variant="outlined"
                      color="danger"
                      startDecorator={<DeleteForever />}
                      sx={{ width: { xs: '100%', md: 'auto' } }}
                      onClick={() => {
                        setRoleToDelete(role);
                        setDisplayDeleteModal(true);
                      }}>
                      {t('roles.eliminar')}
                    </Button>
                  </Stack>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
    </React.Fragment>
  );
}
