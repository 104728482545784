import React, { useEffect, useState } from 'react';
import { Grid, Option, Select } from '@mui/joy';
import Button from '@mui/joy/Button';
import Alert from '@mui/joy/Alert';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import Stack from '@mui/joy/Stack';
import Add from '@mui/icons-material/Add';
import dayjs from 'dayjs';
import http from '../../../../core/infrastructure/HttpService';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';

export function GazettesCreate() {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<string>('ordinary');
  const [selectedJurisdiction, setSelectedJurisdiction] = useState<string>('Venezuela');
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const [maxDate, setMaxDate] = useState('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingCheck, setIsLoadingCheck] = useState<boolean>(false);
  const [showAlertNumber, setShowAlertNumber] = useState(false);
  const [showAlertSuccessForm, setShowAlertSuccessForm] = useState(false);
  const [showAlertErrorForm, setShowAlertErrorForm] = useState(false);
  const [uniqueNumber, setUniqueNumber] = useState<boolean | null>(null);
  const [showAlertFileSize, setShowAlertFileSize] = useState(false);
  const { t } = useTranslation();

  const handleFormSubmitFailed = () => {
    setShowAlertErrorForm(true);
    setTimeout(() => {
      setShowAlertErrorForm(false);
    }, 5000);
  };

  const showDuplicatedGazetteNumberAlert = () => {
    setShowAlertNumber(true);
    setTimeout(() => {
      setShowAlertNumber(false);
    }, 5000);
  };

  const resetForm = () => {
    setInputValue('');
    setMaxDate(getCurrentDate());
    setSelectedType('ordinary');
    setSelectedJurisdiction('Venezuela');
    setSelectedFile(null);
    setUniqueNumber(false);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    setSelectedFile(file);
  };

  const handleAddItemFormValidation = async () => {
    const formData = new FormData();
    const gazetteNumber = parseInt(inputValue, 10);
    formData.append('gazetteNumber', gazetteNumber.toString());
    formData.append('publicationDate', maxDate);
    formData.append('type', selectedType);
    formData.append('jurisdiction', selectedJurisdiction);

    if (selectedFile) {
      formData.append('file', selectedFile);
    }

    try {
      setIsLoading(true);
      await http.post('/gazettes', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setIsLoading(false);

      setShowAlertSuccessForm(true);

      resetForm();

      setTimeout(() => {
        setShowAlertSuccessForm(false);
        setOpen(false);
      }, 3000);
    } catch (error: any) {
      if (error instanceof AxiosError) {
        if (error.response && error.response.status === 409) {
          showDuplicatedGazetteNumberAlert();
        } else if (
          error.response &&
          error.response.status === 400 &&
          error.response.data.message === 'File size is too small.'
        ) {
          setShowAlertFileSize(true);
        } else {
          console.error('Error de Axios:', error);
        }
      } else {
        console.error('Error desconocido:', error);
      }
      setIsLoading(false);
      handleFormSubmitFailed();
    }
  };

  const getCurrentDate = () => {
    return dayjs().format('YYYY-MM-DD');
  };

  useEffect(() => {
    setMaxDate(getCurrentDate());
  }, []);

  async function handleAddItem() {
    setIsLoadingCheck(true);

    const newNumber = Number(inputValue);

    if (!isNaN(newNumber)) {
      try {
        const response = await http.get(`/gazettes?pageSize=1&page=1&gazetteNumber=${newNumber}`);
        console.log(response.data);
        if (response.data.length > 0) {
          showDuplicatedGazetteNumberAlert();
          setUniqueNumber(false);
        } else {
          setUniqueNumber(true);
        }
      } catch (error) {
        console.error('Error al realizar la solicitud', error);
      } finally {
        setIsLoadingCheck(false);
      }
    } else {
      setUniqueNumber(null);
    }
  }

  return (
    <Stack alignItems="flex-end">
      <Button variant="outlined" color="neutral" startDecorator={<Add />} onClick={() => setOpen(true)}>
        {t('gacetas.crear_gaceta')}
      </Button>
      <Modal
        open={open}
        onClose={() => {
          if (!showAlertErrorForm) {
            setOpen(false);
          }
        }}>
        <ModalDialog>
          {!showAlertSuccessForm && <DialogTitle>{t('gacetas.crear_gaceta')}</DialogTitle>}
          <form
            onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
              event.preventDefault();
            }}>
            {showAlertNumber && (
              <Stack sx={{ paddingBottom: '17px' }}>
                <Alert variant="soft" size="sm" color="warning">
                  {t('gacetas.numero_repetido')}
                </Alert>
              </Stack>
            )}
            {showAlertSuccessForm && (
              <Stack sx={{ paddingBottom: '30px', paddingTop: '30px' }}>
                <Alert variant="soft" size="sm" color="success">
                  {t('gacetas.gaceta_creada')}
                </Alert>
              </Stack>
            )}
            {showAlertErrorForm && (
              <Stack sx={{ paddingBottom: '17px' }}>
                <Alert variant="soft" size="sm" color="danger">
                  {t('gacetas.error_enviar_formulario')}
                </Alert>
              </Stack>
            )}
            {showAlertFileSize && (
              <Stack sx={{ paddingBottom: '17px' }}>
                <Alert variant="soft" size="sm" color="warning">
                  {t('gacetas.tamano_archivo_muy_grande')}
                </Alert>
              </Stack>
            )}

            {!showAlertSuccessForm && (
              <Stack spacing={2}>
                <FormControl>
                  <FormLabel> {t('gacetas.numero')} </FormLabel>
                  <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                    <Grid xs={6}>
                      <Stack>
                        <Input
                          type="number"
                          value={inputValue}
                          onChange={e => setInputValue(e.target.value)}
                          style={{
                            borderColor:
                              uniqueNumber === true
                                ? '#01D37E'
                                : uniqueNumber === false || uniqueNumber === undefined
                                  ? 'red'
                                  : '',
                          }}
                          slotProps={{
                            input: {
                              ref: inputRef,
                              min: 1,
                              max: 900000,
                              step: 0.1,
                            },
                          }}
                          required
                        />
                      </Stack>
                    </Grid>
                    <Grid xs={6}>
                      <Stack alignItems="flex-end">
                        <Button
                          size="md"
                          color="success"
                          onClick={handleAddItem}
                          disabled={isLoadingCheck}
                          loading={isLoadingCheck}>
                          {t('gacetas.verificar')}
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </FormControl>
                <FormControl>
                  <FormLabel> {t('gacetas.fecha')} </FormLabel>
                  <Input
                    type="date"
                    value={maxDate}
                    onChange={e => {
                      setMaxDate(e.target.value);
                    }}
                    slotProps={{
                      input: {
                        min: '1900-01-01',
                        max: maxDate,
                      },
                    }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>{t('gacetas.tipo')} </FormLabel>
                  <Select
                    value={selectedType}
                    onChange={(event, value) => {
                      if (value) {
                        setSelectedType(value as string);
                      }
                    }}
                    defaultValue="ordinary">
                    <Option value="ordinary"> {t('gacetas.ordinario')} </Option>
                    <Option value="extraordinary"> {t('gacetas.extraordinario')} </Option>
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>{t('gacetas.jurisdiccion')}</FormLabel>
                  <Select
                    value={selectedJurisdiction}
                    onChange={(event, value) => {
                      if (value) {
                        setSelectedJurisdiction(value as string);
                      }
                    }}
                    defaultValue="Venezuela">
                    <Option value="Venezuela"> Venezuela</Option>
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>{t('gacetas.subir_archivo')}</FormLabel>
                  <Grid xs={4}>
                    <input type="file" onChange={handleFileChange} />
                    {selectedFile && (
                      <Grid>
                        <FormLabel>
                          {t('gacetas.archivo_seleccionado')} {selectedFile.name}
                        </FormLabel>
                        <FormLabel>
                          {t('gacetas.tipo_archivo')} {selectedFile.type}
                        </FormLabel>
                        <FormLabel>
                          {t('gacetas.tamaño')} {selectedFile.size}bytes
                        </FormLabel>
                      </Grid>
                    )}
                  </Grid>
                </FormControl>
                <Button
                  loading={isLoading}
                  color="primary"
                  type="submit"
                  disabled={!selectedFile || uniqueNumber === false}
                  onClick={handleAddItemFormValidation}>
                  {t('gacetas.enviar')}
                </Button>
              </Stack>
            )}
          </form>
        </ModalDialog>
      </Modal>
    </Stack>
  );
}
