import { atom } from 'recoil';
import { Role } from '../../roles/role.interface';
import { ManagerAppUser } from '../../users/domain/manager-app-user.interface';

export const roleAtom = atom<Role | null>({
  key: 'userRole',
  default: null,
});

export const managerAppUserAtom = atom<ManagerAppUser | null>({
  key: 'managerAppUser',
  default: null,
});
