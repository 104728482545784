enum ApiPermission {
  GetUsers = 'users:get',
  CreateUsers = 'users:create',
  UpdateUsers = 'users:update',
  DeleteUsers = 'users:delete',

  // Roles
  GetRoles = 'roles:get',
  CreateRoles = 'roles:create',
  UpdateRoles = 'roles:update',
  DeleteRoles = 'roles:delete',

  // Emails
  GetEmails = 'emails:get',
  ResendEmails = 'emails:resend',

  // Gazettes
  GetGazettes = 'gazettes:get',
  CreateGazettes = 'gazettes:create',
  UpdateGazettes = 'gazettes:update',
  DeleteGazettes = 'gazettes:delete',
}

export default ApiPermission;
