import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';

export default function WelcomePage() {
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    if (auth.currentUser) {
      navigate('/dashboard');
    } else {
      navigate('/sign-in');
    }
  }, [navigate]);

  return <h1>Loading screen...</h1>;
}
