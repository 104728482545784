// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Link as RouterLink, useMatches } from 'react-router-dom';
import { Breadcrumbs } from '@mui/joy';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import Typography from '@mui/joy/Typography';
import Link from '@mui/joy/Link';
import { useEffect } from 'react';

export default function DynamicBreadcrumbs() {
  const matches = useMatches();

  const crumbs = matches.filter(match => Boolean(match.handle?.crumb)).map(match => match.handle.crumb);

  const breadcrumbItems = matches.map((match, index) => {
    const label = crumbs[index];
    const path = match.pathname;

    if (index === matches.length - 1) {
      useEffect(() => {
        document.title = `${label} - Venley`;
      }, [label]);

      return (
        <Typography key={path} fontWeight={500} fontSize={12}>
          {label}
        </Typography>
      );
    }

    // For other items, render Links
    return (
      <Link key={path} underline="none" color="primary" component={RouterLink} to={path}>
        {label}
      </Link>
    );
  });

  return (
    <Breadcrumbs size="sm" aria-label="breadcrumbs" separator={<ChevronRightRoundedIcon />} sx={{ pl: 0 }}>
      {breadcrumbItems}
    </Breadcrumbs>
  );
}
