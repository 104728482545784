import React, { useEffect, useState } from 'react';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import Table from '@mui/joy/Table';
import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  Option,
  Select,
  Snackbar,
} from '@mui/joy';
import Divider from '@mui/joy/Divider';
import DeleteForever from '@mui/icons-material/DeleteForever';
import { Edit, RestartAlt } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import http from '../../../core/infrastructure/HttpService';
import { ManagerAppUser } from '../../domain/manager-app-user.interface';
import IconButton from '@mui/joy/IconButton';
import Box from '@mui/joy/Box';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import { useTranslation } from 'react-i18next';

export default function UsersListPage() {
  const [users, setUsers] = useState<ManagerAppUser[]>([]);
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
  const [userToDeactivate, setUserToDeactivate] = useState<ManagerAppUser | null>(null);
  const [userToReactivate, setUserToReactivate] = useState<ManagerAppUser | null>(null);
  const [displayReactivateModal, setDisplayReactivateModal] = useState<boolean>(false);
  const [showReactivationError, setShowReactivationError] = useState<boolean>(false);
  const [showDeletionError, setShowDeletionError] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingReactivate, setLoadingReactivate] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    async function fetchUsers() {
      setErrorMessage(null);
      try {
        const response = await http.get(`/manager-app-users?page=${page}&pageSize=${pageSize}`);
        setUsers(response.data);
      } catch (e) {
        console.log(e);
        setErrorMessage('Ha ocurrido un error al cargar los usuarios, por favor intenta de nuevo más tarde.');
      }
    }

    fetchUsers();
  }, [page, pageSize]);

  async function deactivateUser() {
    setLoadingDelete(true);
    try {
      await http.delete(`/manager-app-users/${userToDeactivate?.uuid}`);
      setUsers(users.map(user => (user.uuid === userToDeactivate?.uuid ? { ...user, active: false } : user)));
      setDisplayDeleteModal(false);
    } catch (e) {
      console.log(e);
      setShowDeletionError(true);
    } finally {
      setUserToDeactivate(null);
      setLoadingDelete(false);
    }
  }

  async function reactivateUser() {
    setLoadingReactivate(true);
    try {
      await http.patch(`/manager-app-users/${userToReactivate?.uuid}`, {
        name: userToReactivate?.name,
        email: userToReactivate?.email,
        roleUuid: userToReactivate?.role?.uuid,
        active: true,
      });
      setUsers(users.map(user => (user.uuid === userToReactivate?.uuid ? { ...user, active: true } : user)));

      setDisplayReactivateModal(false);
    } catch (e) {
      console.log(e);
      setShowReactivationError(true);
    } finally {
      setUserToReactivate(null);
      setLoadingReactivate(false);
    }
  }

  return (
    <React.Fragment>
      <Snackbar
        autoHideDuration={3000}
        color="danger"
        variant="solid"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showDeletionError}
        onClose={() => setShowDeletionError(false)}>
        {t('usuarios.error_desactivar')}
      </Snackbar>

      <Snackbar
        autoHideDuration={3000}
        color="danger"
        variant="solid"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showReactivationError}
        onClose={() => setShowReactivationError(false)}>
        {t('usuarios.error_reactivar')}
      </Snackbar>

      <Snackbar
        autoHideDuration={3000}
        color="danger"
        variant="solid"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!errorMessage}
        onClose={() => setErrorMessage(null)}>
        {errorMessage}
      </Snackbar>

      <Modal open={displayDeleteModal} onClose={() => setDisplayDeleteModal(false)}>
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRoundedIcon />
            {t('usuarios.confirmacion')}
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Typography>
              {t('usuarios.desactiva_usuario')}
              <b>{userToDeactivate?.name}</b>?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="solid" color="danger" onClick={deactivateUser} loading={loadingDelete}>
              {t('usuarios.desactivar')}
            </Button>
            <Button
              variant="plain"
              color="neutral"
              onClick={() => {
                setDisplayDeleteModal(false);
                setUserToDeactivate(null);
              }}>
              {t('usuarios.cancelar')}
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>

      <Modal open={displayReactivateModal} onClose={() => setDisplayReactivateModal(false)}>
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRoundedIcon />
            {t('usuarios.confirmacion')}
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Typography>
              {t('usuarios.reactivar_usuario')}
              <b>{userToDeactivate?.name}</b>?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="solid" color="success" onClick={reactivateUser} loading={loadingReactivate}>
              {t('usuarios.reactivar')}
            </Button>
            <Button
              variant="plain"
              color="neutral"
              onClick={() => {
                setDisplayReactivateModal(false);
                setUserToReactivate(null);
              }}>
              {t('usuarios.cancelar')}
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>

      <Sheet
        sx={{
          mx: 'auto',
          my: 4,
          py: 3,
          px: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          borderRadius: 'sm',
          boxShadow: 'md',
        }}>
        <Typography level="h2">{t('usuarios.usuarios')}</Typography>

        <Table
          hoverRow
          stripe="odd"
          sx={{
            width: '100%',
            tableLayout: 'fixed',
          }}>
          <thead>
            <tr>
              <th style={{ width: '20%' }}>{t('usuarios.nombre')}</th>
              <th style={{ width: '20%' }}>{t('usuarios.correo')}</th>
              <th style={{ width: '10%' }}>{t('usuarios.rol')}</th>
              <th style={{ width: '20%', textAlign: 'center' }}>{t('usuarios.estado')}</th>
              <th style={{ width: '30%', textAlign: 'center' }}>{t('usuarios.acciones')}</th>{' '}
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr key={user.uuid}>
                <td data-label="Nombre" style={{ maxWidth: '50%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {user.name}
                </td>
                <td
                  data-label="Correo electrónico"
                  style={{ maxWidth: '50%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {user.email}
                </td>
                <td data-label="Rol" style={{ maxWidth: '50%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {user.role ? (
                    <Chip variant="outlined" color="primary">
                      {user.role.name}
                    </Chip>
                  ) : (
                    <Chip variant="outlined">{t('usuarios.sin_rol')}</Chip>
                  )}
                </td>
                <td data-label="Estado" style={{ textAlign: 'center' }}>
                  <Chip color={user.active ? 'success' : 'danger'}>{user.active ? 'Activo' : 'Inactivo'}</Chip>
                </td>
                <td
                  data-label="Acciones"
                  style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    spacing={1}
                    justifyContent="center"
                    alignItems="center">
                    <Button
                      size="sm"
                      variant="solid"
                      startDecorator={<Edit />}
                      onClick={() => navigate(`/app/users/${user.uuid}/edit`)}
                      sx={{ flex: '1 1 auto' }}>
                      {t('usuarios.editar')}
                    </Button>
                    {user.active ? (
                      <Button
                        size="sm"
                        variant="outlined"
                        color="danger"
                        startDecorator={<DeleteForever />}
                        onClick={() => {
                          setUserToDeactivate(user);
                          setDisplayDeleteModal(true);
                        }}
                        sx={{ flex: '1 1 auto' }}>
                        {t('usuarios.desactivar')}
                      </Button>
                    ) : (
                      <Button
                        size="sm"
                        variant="outlined"
                        color="success"
                        startDecorator={<RestartAlt />}
                        onClick={() => {
                          setUserToReactivate(user);
                          setDisplayReactivateModal(true);
                        }}
                        sx={{ flex: '1 1 auto' }}>
                        {t('usuarios.reactivar')}
                      </Button>
                    )}
                  </Stack>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
    </React.Fragment>
  );
}
