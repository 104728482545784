import { ReactElement } from 'react';
import { useRecoilState } from 'recoil';
import { roleAtom } from '../application/auth-atoms';
import { useMatches, useNavigate } from 'react-router-dom';
import ApiPermission from '../../core/domain/api-permissions';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';

export interface Handle {
  permission?: ApiPermission;
  crumb?: (data: any) => ReactElement;
}

export const PermissionsWrapper = ({ children }: { children: ReactElement }): ReactElement => {
  const [role] = useRecoilState(roleAtom);
  const matches: any = useMatches();
  const navigate = useNavigate();

  if (!role) {
    return children;
  }

  const requiredPermissions = matches
    .filter((match: { handle?: Handle }) => Boolean(match.handle?.permission))
    .map((match: any) => match.handle?.permission);

  // Find one permission that is not in the user's role
  const missingPermission = requiredPermissions.find(
    (permission: ApiPermission) => !role?.permissions.includes(permission),
  );

  if (missingPermission) {
    navigate('/app');
    return (
      <Box>
        <Typography>
          You do not have permission to access this page. You are missing the <strong>{missingPermission}</strong>{' '}
          permission.
        </Typography>
      </Box>
    );
  }

  if (requiredPermissions.length === 0) {
    return children;
  }

  return children;
};
