import { useState } from 'react';
import Stack from '@mui/joy/Stack';
import { Button, FormControl, FormLabel, Grid, Input, Sheet, Typography } from '@mui/joy';
import ReturnButtonGrid from '../../components/ReturnButtonGrid';
import CustomButtonGroup from '../../components/CustomButtonGroup';
import DownloadGrid from '../../components/DownloadGrid';
import CustomBox from '../../components/CustomBox';
import CustomInput from '../../components/CustomInput';
import CustomApproveButton from '../../components/CustomApproveButton';
import { useTranslation } from 'react-i18next';

export default function TicketChangePage() {
  const [activeButton, setActiveButton] = useState<number | null>(null);
  const { t } = useTranslation();
  const handleButtonClick = (buttonIndex: number) => {
    setActiveButton(buttonIndex);
  };

  return (
    <Stack spacing={3} alignItems="start">
      <Typography
        className="title-heading"
        sx={{
          paddingTop: '15px',
          fontFamily: 'Maison Neue',
          fontWeight: 'bold',
        }}
        level="h2">
        {t('entrada.entrada_consulta')}
      </Typography>
      <CustomButtonGroup activeButton={activeButton} handleButtonClick={handleButtonClick} />
      <Sheet
        sx={{
          mx: 'auto',
          my: 4,
          py: 4,
          px: 3,
          borderRadius: 'sm',
          boxShadow: 'md',
          width: '100%',
        }}>
        <Grid container style={{ paddingBottom: '15px' }}>
          <Grid xs={12} sm={6} md={2} container justifyContent="flex-start">
            <Typography style={{ paddingTop: '15px', fontFamily: 'Maison Neue' }}>Ticket #1238127</Typography>
          </Grid>
          <Grid xs={12} sm={6} md={2} container justifyContent={{ xs: 'flex-start', sm: 'center' }}>
            <Typography style={{ paddingTop: '15px', fontFamily: 'Maison Neue' }}>Ángel Niño</Typography>
          </Grid>
          <Grid xs={12} sm={6} md={2} container justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}>
            <Typography style={{ paddingTop: '15px', fontFamily: 'Maison Neue' }}>11-11-2023</Typography>
          </Grid>
          <Grid
            style={{ paddingTop: '10px' }}
            xs={12}
            sm={6}
            md={6}
            container
            justifyContent={{ xs: 'flex-start', sm: 'flex-end' }}>
            <ReturnButtonGrid />
          </Grid>
        </Grid>
        <form
          onSubmit={event => {
            event.preventDefault();
          }}>
          <Grid container spacing={2}>
            <Grid xs={12} sm={6} md={6}>
              <FormControl style={{ paddingBottom: '7%' }}>
                <FormLabel>{t('entrada.consulta')}</FormLabel>
                <Input
                  style={{
                    borderRadius: '10px',
                    border: '1px solid #9A9AA9',
                    color: '#4F4E69',
                    fontFamily: 'Maison Neue',
                  }}
                  name="name"
                  type="text"
                  placeholder="Sebastián Rojas"
                />
              </FormControl>
              <CustomBox />
              <CustomInput />
            </Grid>
            <Grid xs={12} sm={6} md={6}>
              <FormControl>
                <FormLabel>{t('entrada.asignado')}</FormLabel>
                <Input
                  style={{
                    borderRadius: '10px',
                    border: '1px solid #9A9AA9',
                    color: '#4F4E69',
                    fontFamily: 'Maison Neue',
                  }}
                  name="email"
                  type="email"
                  placeholder="Ángel Niño"
                />
              </FormControl>
              <DownloadGrid />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            sx={{
              paddingTop: '16px',
              justifyContent: 'center',
              '@media (min-width: 960px)': {
                justifyContent: 'flex-end',
              },
            }}>
            <Grid style={{ paddingTop: '16px' }}>
              <Button
                sx={{
                  color: '#01D37E',
                  backgroundColor: 'white',
                  height: '39px',
                  borderRadius: '10px',
                  border: '1px solid #01D37E',
                  padding: '9px 23px',
                  gap: '10px',
                  fontFamily: 'Maison Neue',
                  fontWeight: 'bold',
                  '&:hover': {
                    backgroundColor: '#01D37E',
                    color: 'white',
                  },
                }}>
                {t('entrada.rechazar')}
              </Button>
            </Grid>
            <Grid>
              <CustomApproveButton />
            </Grid>
          </Grid>
        </form>
      </Sheet>
    </Stack>
  );
}
