import React, { useEffect, useState } from 'react';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import { useNavigate, useParams } from 'react-router-dom';
import { Checkbox, FormHelperText, Option, Select, Snackbar, Tooltip } from '@mui/joy';
import { ManagerAppUser } from '../../domain/manager-app-user.interface';
import http from '../../../core/infrastructure/HttpService';
import { Role } from '../../../roles/role.interface';
import { useTranslation } from 'react-i18next';

export default function UserCreatePage() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [nameError, setNameError] = useState<string | null>(null);
  const [roleError, setRoleError] = useState<string | null>(null);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [selectedRoleId, setSelectedRoleId] = useState<string | null>(null);
  const [roles, setRoles] = useState<Role[] | null>(null);
  const [active, setActive] = useState(true);
  const { t } = useTranslation();
  const { id } = useParams();
  const [managerAppUser, setManagerAppUser] = useState<ManagerAppUser | null>(null);

  useEffect(() => {
    async function fetchUser() {
      setLoading(true);
      try {
        const response = await http.get(`/manager-app-users/${id}`);
        const managerUser: ManagerAppUser = response.data;
        setManagerAppUser(managerUser);
        setName(managerUser.name);
        setEmail(managerUser.email);
        setActive(managerUser.active);
        if (managerUser.role) {
          setSelectedRoleId(managerUser.role.uuid);
        }
      } catch (e) {
        setErrorMessage('Ocurrió un problema al obtener el usuario a editar, por favor intenta de nuevo más tarde.');
        console.log(e);
      } finally {
        setLoading(false);
      }
    }

    if (id) {
      fetchUser();
    } else {
      setName('');
      setEmail('');
      setActive(true);
      setSelectedRoleId(null);

      setNameError(null);
      setEmailError(null);
      setRoleError(null);
    }
  }, [id]);

  useEffect(() => {
    async function fetchRoles() {
      try {
        const response = await http.get('/roles');
        setRoles(response.data);
        setLoading(false);
      } catch (e) {
        setErrorMessage('Ocurrió un problema al obtener los roles, por favor intenta de nuevo más tarde.');
        console.log(e);
      } finally {
        setLoading(false);
      }
    }

    fetchRoles();
  }, []);

  async function submitUserForm() {
    if (selectedRoleId === null) {
      setErrorMessage('Debes escoger un rol para este usuario.');
      setRoleError('Debes escoger un rol para este usuario.');
      return;
    }

    setLoading(true);
    setOpen(false);
    setNameError(null);
    setErrorMessage(null);
    setEmailError(null);
    setRoleError(null);

    const payload = {
      name,
      email,
      active,
      roleUuid: selectedRoleId,
    };

    try {
      if (id) {
        await http.patch(`/manager-app-users/${managerAppUser?.uuid}`, payload);
      } else {
        await http.post('/manager-app-users', payload);
      }
      setOpen(true);
    } catch (e: any) {
      if (e.response?.data.code === 'already-exists') {
        setErrorMessage('Este correo electrónico ya se encuentra registrado.');
      } else {
        setErrorMessage('Ha ocurrido un error inesperado, por favor intenta de nuevo más tarde.');
      }

      setLoading(false);
    }
  }

  return (
    <>
      <Snackbar
        autoHideDuration={2000}
        color="success"
        variant="solid"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={() => {
          navigate('/app/users');
        }}>
        Se ha {id ? 'actualizado' : 'creado'} el usuario exitosamente. Serás redirigido en un momento.
      </Snackbar>

      <Snackbar
        autoHideDuration={2000}
        color="danger"
        variant="solid"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={errorMessage !== null}
        onClose={() => {
          setErrorMessage(null);
        }}>
        {errorMessage}
      </Snackbar>

      <Typography level="h2" component="h1" sx={{ mt: 1, mb: 2 }}>
        {id ? 'Editar' : 'Crear'} {t('usuarios.usuario')}
      </Typography>

      <Sheet
        sx={{
          mx: 'auto',
          my: 4,
          py: 3,
          px: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          borderRadius: 'sm',
          boxShadow: 'md',
        }}>
        <form
          onSubmit={event => {
            event.preventDefault();
            submitUserForm();
          }}>
          <Stack direction="column" spacing={2}>
            <FormControl disabled={loading} error={nameError !== null}>
              <FormLabel>{t('usuarios.nombre_usuario')}</FormLabel>
              <Input
                name="name"
                type="text"
                placeholder="Ingresa el nombre del usuario"
                required
                value={name}
                onChange={e => setName(e.target.value)}
              />

              <FormHelperText color="danger">{nameError}</FormHelperText>
            </FormControl>

            <FormControl disabled={loading} error={emailError !== null}>
              <FormLabel>{t('usuarios.correo')}</FormLabel>
              <Input
                name="email"
                type="email"
                placeholder="Ingresa el correo electrónico del usuario"
                required
                value={email}
                onChange={e => setEmail(e.target.value)}
              />

              <FormHelperText color="danger">{emailError}</FormHelperText>
            </FormControl>

            <FormControl disabled={loading} error={roleError !== null}>
              <FormLabel>{t('usuarios.seleccionar_usuario')}</FormLabel>

              <Select
                required
                value={selectedRoleId}
                name="selectedRole"
                onChange={(e, value) => setSelectedRoleId(value)}>
                {roles?.map(role => (
                  <Option key={role.uuid} value={role.uuid}>
                    {role.name}
                  </Option>
                ))}
              </Select>

              <FormHelperText color="danger">{roleError}</FormHelperText>
            </FormControl>

            {id ? (
              <Checkbox label="Activo" checked={active} onChange={e => setActive(e.target.checked)} />
            ) : (
              <Tooltip title="Los nuevos usuarios están activos por defecto.">
                <Checkbox label="Activo" checked={active} disabled />
              </Tooltip>
            )}

            <Button type="submit" disabled={loading} loading={loading}>
              {id ? 'Actualizar' : 'Crear'} {t('usuarios.usuario')}
            </Button>
          </Stack>
        </form>
      </Sheet>
    </>
  );
}
