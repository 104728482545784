import { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useRecoilState } from 'recoil';
import { managerAppUserAtom, roleAtom } from '../application/auth-atoms';
import http from '../../core/infrastructure/HttpService';

const AuthWrapper = ({ children }: { children: ReactElement }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useRecoilState(roleAtom);
  const [managerAppUser, setManagerAppUser] = useRecoilState(managerAppUserAtom);

  const navigate = useNavigate();
  const auth = getAuth();

  async function getManagerAppUser(): Promise<void> {
    const user = auth.currentUser;
    if (user) {
      try {
        const response = await http.get('/manager-app-users/me');
        setManagerAppUser(response.data);
        setRole(response.data.role);
      } catch (e) {
        console.error(e);
      }
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        setIsAuthenticated(true);
        getManagerAppUser();
      } else {
        navigate('/sign-in');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  return isAuthenticated ? children : null;
};

export default AuthWrapper;
