import * as React from 'react';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import { TabPanel } from '@mui/joy';
import UpdateUserProfileForm from './UpdateUserProfileForm';
import UpdateUserPasswordForm from './UpdateUserPasswordForm';

export default function SettingsPage() {
  return (
    <Box sx={{ flex: 1, width: '100%' }}>
      <Box
        sx={{
          position: 'sticky',
          top: { sm: -100, md: -110 },
          bgcolor: 'background.body',
          zIndex: 9995,
        }}>
        <Typography level="h2" component="h1" sx={{ mt: 1, mb: 2 }}>
          Configuración
        </Typography>
        <Tabs
          defaultValue={0}
          sx={{
            bgcolor: 'transparent',
          }}>
          <TabList
            tabFlex={1}
            size="sm"
            sx={{
              justifyContent: 'left',
              [`&& .${tabClasses.root}`]: {
                fontWeight: '600',
                flex: 'initial',
                color: 'text.tertiary',
                [`&.${tabClasses.selected}`]: {
                  bgcolor: 'transparent',
                  color: 'text.primary',
                  '&::after': {
                    height: '2px',
                    bgcolor: 'primary.500',
                  },
                },
              },
            }}>
            <Tab sx={{ borderRadius: '6px 6px 0 0' }} indicatorInset value={0}>
              Mi perfil
            </Tab>
            <Tab sx={{ borderRadius: '6px 6px 0 0' }} indicatorInset value={1}>
              Actualizar contraseña
            </Tab>
          </TabList>

          <TabPanel value={0}>
            <UpdateUserProfileForm />
          </TabPanel>
          <TabPanel value={1}>
            <UpdateUserPasswordForm />
          </TabPanel>
        </Tabs>
      </Box>
    </Box>
  );
}
