import { createContext, useContext } from 'react';
import { GazetteListManagementInterface, useGazetteListManagement } from './hooks/useGazetteListManagement';

const GazetteListPageContext = createContext<GazetteListManagementInterface | null>(null);

export const GazetteListManagementProvider = ({ children }: { children: any }) => {
  const gazetteListManagement = useGazetteListManagement();

  return <GazetteListPageContext.Provider value={gazetteListManagement}>{children}</GazetteListPageContext.Provider>;
};

export const useGazetteListManagementContext = () => {
  const context = useContext(GazetteListPageContext);

  if (context === null) {
    throw new Error('useGazetteListManagementContext must be used within a GazetteListManagementProvider');
  }
  return context;
};
