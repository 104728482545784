import React, { useState } from 'react';
import { Avatar, Grid, Input } from '@mui/joy';
import SendSharpIcon from '@mui/icons-material/SendSharp';

export default function CustomInput() {
  const [enviado, setEnviado] = useState(false);

  const handleClick = () => {
    setEnviado(true);

    setTimeout(() => {
      setEnviado(false);
    }, 1000);
  };
  return (
    <Grid xs={12} sm={12} md={12}>
      <Input
        style={{
          height: '30px',
          borderRadius: '10px',
          border: '1px solid #9A9AA9',
          fontFamily: 'Maison Neue',
        }}
        endDecorator={
          <SendSharpIcon
            onClick={handleClick}
            style={{ color: enviado ? 'rgba(51, 102, 255, 0.7)' : 'inherit', cursor: 'pointer' }}
          />
        }
        startDecorator={<Avatar alt="img" src="/ruta/a/la/imagen.jpg" style={{ width: '30px', height: '30px' }} />}
        placeholder="Escribe un comentario..."
      />
    </Grid>
  );
}
