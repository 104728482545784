import React, { useEffect, useState } from 'react';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import Table from '@mui/joy/Table';
import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalDialog,
  Option,
  Select,
  Snackbar,
  Tooltip,
} from '@mui/joy';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import Box from '@mui/joy/Box';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import { Email } from '../domain/email.interface';
import http from '../../core/infrastructure/HttpService';
import { RestartAlt } from '@mui/icons-material';
import { useRecoilState } from 'recoil';
import { roleAtom } from '../../auth/application/auth-atoms';
import ApiPermission from '../../core/domain/api-permissions';
import { useTranslation } from 'react-i18next';

export function EmailListPage() {
  const [emails, setEmails] = useState<Email[]>([]);
  const [emailToResend, setEmailToResend] = useState<Email | null>(null);
  const [displayResendModal, setDisplayResendModal] = useState<boolean>(false);
  const [loadingResend, setLoadingResend] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [nextPageCursor, setNextPageCursor] = useState<string | null>(null);
  const [pageSize, setPageSize] = useState<number>(10);
  const [role] = useRecoilState(roleAtom);
  const [displayResendSucceded, setDisplayResendSucceded] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    async function fetchEmails() {
      setErrorMessage(null);
      try {
        const response = await http.get(`/emails?pageSize=${pageSize}`);
        setEmails(response.data.payload);
        setNextPageCursor(response.data.nextPageCursor);
      } catch (e) {
        console.log(e);
        setErrorMessage(
          'Ha ocurrido un error al cargar los correos electrónicos, por favor intenta de nuevo más tarde.',
        );
      }
    }

    fetchEmails();
  }, [pageSize]);

  async function resendEmail() {
    setLoadingResend(true);
    try {
      await http.post(`/emails/${emailToResend?.uuid}/resend`);
      setDisplayResendSucceded(true);
      setDisplayResendModal(false);
    } catch (e) {
      console.log(e);
      setErrorMessage('Ha ocurrido un error al reenviar el correo, por favor intenta de nuevo más tarde.');
      setDisplayResendModal(false);
    } finally {
      setEmailToResend(null);
      setLoadingResend(false);
    }
  }

  async function getNextPage() {
    setErrorMessage(null);
    try {
      const response = await http.get('/emails', { params: { cursor: nextPageCursor, limit: pageSize } });
      setEmails(response.data.payload);
      setNextPageCursor(response.data.nextPageCursor);
    } catch (e) {
      console.log(e);
      setErrorMessage('Ha ocurrido un error al cargar los usuarios, por favor intenta de nuevo más tarde.');
    }
  }

  return (
    <React.Fragment>
      <Snackbar
        autoHideDuration={3000}
        color="danger"
        variant="solid"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!errorMessage}
        onClose={() => setErrorMessage(null)}>
        {errorMessage}
      </Snackbar>

      <Snackbar
        autoHideDuration={3000}
        color="success"
        variant="solid"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={displayResendSucceded}
        onClose={() => setDisplayResendSucceded(false)}>
        {t('correo.correo_añadido')}
      </Snackbar>

      <Modal open={displayResendModal} onClose={() => setDisplayResendModal(false)}>
        <ModalDialog variant="outlined" role="alertdialog">
          <DialogTitle>
            <WarningRoundedIcon />
            {t('correo.confirmacion')}
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Stack direction="column" gap={2}>
              <Typography fontWeight="lighter">
                {t('correo.reenviar_correo')} &quot;{emailToResend?.subject}&quot; {t('correo.al_correo')}
                <b>{emailToResend?.sentTo}</b>?
              </Typography>
              <Typography>{t('correo.correo_enviado')}</Typography>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant="solid" color="warning" onClick={resendEmail} loading={loadingResend}>
              {t('correo.reenviar')}
            </Button>
            <Button
              variant="plain"
              color="neutral"
              onClick={() => {
                setDisplayResendModal(false);
                setEmailToResend(null);
              }}>
              {t('correo.cancelar')}
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
      <Sheet
        sx={{
          mx: 'auto',
          my: 4,
          py: 3,
          px: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          borderRadius: 'sm',
          boxShadow: 'md',
        }}>
        <Typography level="h2">{t('correo.correos_enviados')}</Typography>

        <Table
          hoverRow
          stripe="odd"
          sx={{
            width: '100%',
            tableLayout: 'fixed',
            textAlign: 'start',
            '& thead th, & tbody td': {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },

            '@media (max-width: 960px)': {
              '& thead th': { width: 'auto', whiteSpace: 'unset' },
              '& tbody td': { width: 'auto', whiteSpace: 'unset' },
            },
          }}>
          <thead>
            <tr>
              <th>{t('correo.recipiente')}</th>
              <th>{t('correo.enviado')}</th>
              <th>{t('correo.asunto')}</th>
              <th>{t('correo.estado')}</th>
              <th>{t('correo.acciones')}</th>
            </tr>
          </thead>
          <tbody>
            {emails.map(email => (
              <tr key={email.uuid}>
                <td>
                  <Tooltip title={email.sentTo}>
                    <Typography>{email.sentToName}</Typography>
                  </Tooltip>
                </td>
                <td>{email.sentFrom}</td>
                <td>{email.subject}</td>
                <td>
                  <Chip color={email.succeeded ? 'success' : 'danger'}>
                    {email.succeeded ? 'Enviado' : 'Falló al enviar'}
                  </Chip>
                </td>
                <td>
                  {role?.permissions?.includes(ApiPermission.ResendEmails) && (
                    <Button
                      size="sm"
                      variant="outlined"
                      color="success"
                      disabled={email.succeeded}
                      startDecorator={<RestartAlt />}
                      onClick={() => {
                        setEmailToResend(email);
                        setDisplayResendModal(true);
                      }}
                      sx={{
                        width: 'fit-content',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        '@media (max-width: 960px)': {
                          width: '100%',
                          fontSize: '0.8rem',
                        },
                      }}>
                      {t('correo.reenviar')}
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={5}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    justifyContent: 'flex-end',
                  }}>
                  <FormControl orientation="horizontal" size="sm">
                    <FormLabel>{t('correo.correos_pagina')}</FormLabel>
                    <Select value={pageSize} onChange={(e, value) => setPageSize(value || 10)}>
                      <Option value={10}>10</Option>
                      <Option value={25}>25</Option>
                      <Option value={50}>50</Option>
                    </Select>
                  </FormControl>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <IconButton
                      size="sm"
                      color="neutral"
                      variant="outlined"
                      disabled={nextPageCursor === null}
                      onClick={getNextPage}
                      sx={{ bgcolor: 'background.surface' }}>
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  </Box>
                </Box>
              </td>
            </tr>
          </tfoot>
        </Table>
      </Sheet>
    </React.Fragment>
  );
}
