import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importa tus archivos de traducción
import translationES from './local/es.json';

i18n
  .use(initReactI18next) // react-i18next
  .init({
    resources: {
      es: {
        translation: translationES, // Archivo de traducción para español
      },
    },
    lng: 'es', // Idioma predeterminado
    fallbackLng: 'es', // Idioma de respaldo
    interpolation: {
      escapeValue: false, // Evita escapar caracteres HTML
    },
  });

export default i18n;
