import React from 'react';
import Grid from '@mui/joy/Grid';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import Button from '@mui/joy/Button';
import ArchiveSharpIcon from '@mui/icons-material/ArchiveSharp';
import { useTranslation } from 'react-i18next';

export default function DownloadGrid() {
  const { t } = useTranslation();
  return (
    <Grid xs={12} sm={12} md={12} container spacing={2} sx={{ paddingTop: '11%', paddingLeft: '3%', width: '100%' }}>
      <List
        style={{
          width: '100%',
          height: '100%',
          borderRadius: '10px',
          border: '1px solid #9A9AA9',
        }}>
        {[1, 2, 3, 4].map(index => (
          <ListItem
            key={index}
            sx={{
              display: 'block',
            }}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent={{ xs: 'space-between', sm: 'flex-start' }}
              width={{ xs: '100%', sm: 'auto' }}
              flexDirection={{ xs: 'column', sm: 'row' }}
              mb={{ xs: 2, sm: 0 }}>
              <Box display="flex" alignItems="center">
                <IconButton sx={{ marginRight: { xs: 1, sm: 2 } }}>
                  <ArchiveSharpIcon />
                </IconButton>
                <Typography
                  style={{
                    fontFamily: 'Maison Neue',
                    fontSize: '13px',
                  }}>
                  {t('entrada.archivos.archivo')}
                </Typography>
              </Box>
              <Box flexGrow={1} display="flex" justifyContent="flex-end">
                <Button
                  sx={{
                    color: 'rgba(51, 102, 255, 0.7) !important',
                    backgroundColor: 'transparent !important',
                    border: 'none',
                    fontFamily: 'Maison Neue',
                    '&:hover': {
                      backgroundColor: '#3366FF !important',
                      color: 'white !important',
                    },
                  }}>
                  {t('entrada.archivos.descargar')}
                </Button>
              </Box>
            </Box>
          </ListItem>
        ))}
      </List>
    </Grid>
  );
}
