import React from 'react';
import ButtonGroup from '@mui/joy/ButtonGroup';
import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';
import Grid from '@mui/joy/Grid';
import { useTranslation } from 'react-i18next';

interface CustomButtonGroupProps {
  activeButton: number | null;
  handleButtonClick: (buttonIndex: number) => void;
}

export default function CustomButtonGroup({ activeButton, handleButtonClick }: CustomButtonGroupProps) {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent="start" spacing={2}>
      <Grid xs={12} lg={6} md={6}>
        <Stack sx={{ maxWidth: '100%' }}>
          <ButtonGroup
            sx={{
              '--ButtonGroup-separatorSize': 0,
              '--ButtonGroup-separatorColor': 'transparent',
            }}
            size="sm"
            aria-label="success button group"
            spacing="0.1rem">
            <Button
              sx={{
                color: activeButton === 0 ? 'white' : '#01D37E',
                backgroundColor: activeButton === 0 ? '#01D37E' : 'white',
                height: '39px',
                borderRadius: '10px',
                border: 'none',
                padding: '9px 16px',
                gap: '10px',
                fontFamily: 'Maison Neue',
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: '#01D37E',
                  color: 'white',
                },
              }}
              onClick={() => handleButtonClick(0)}>
              {t('entrada.contenedor.gacetas')}
            </Button>
            <Button
              sx={{
                color: activeButton === 1 ? 'white' : '#01D37E',
                backgroundColor: activeButton === 1 ? '#01D37E' : 'white',
                height: '39px',
                borderRadius: '10px',
                border: 'none',
                padding: '9px 16px',
                gap: '10px',
                fontFamily: 'Maison Neue',
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: '#01D37E',
                  color: 'white',
                },
              }}
              onClick={() => handleButtonClick(1)}>
              {t('entrada.contenedor.actos')}
            </Button>
            <Button
              sx={{
                color: activeButton === 2 ? 'white' : '#01D37E',
                backgroundColor: activeButton === 2 ? '#01D37E' : 'white',
                height: '39px',
                borderRadius: '10px',
                border: 'none',
                padding: '9px 16px',
                gap: '10px',
                fontFamily: 'Maison Neue',
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: '#01D37E',
                  color: 'white',
                },
              }}
              onClick={() => handleButtonClick(2)}>
              {t('entrada.contenedor.organismos')}
            </Button>
          </ButtonGroup>
          <ButtonGroup
            sx={{
              '--ButtonGroup-separatorSize': 0,
              '--ButtonGroup-separatorColor': 'transparent',
              paddingTop: '10px',
              '@media (max-width: 960px)': {
                display: 'block',
              },
              '@media (min-width: 960px)': {
                display: 'flex',
              },
            }}
            size="sm"
            aria-label="success button group"
            spacing="0.1rem">
            <Button
              sx={{
                color: activeButton === 3 ? 'white' : '#01D37E',
                backgroundColor: activeButton === 3 ? '#01D37E' : 'white',
                height: '39px',
                borderRadius: '10px',
                border: 'none',
                padding: '9px 16px',
                gap: '10px',
                fontFamily: 'Maison Neue',
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: '#01D37E',
                  color: 'white',
                },
              }}
              onClick={() => handleButtonClick(3)}>
              {t('entrada.contenedor.ejecutivo')}
            </Button>
            <Button
              sx={{
                color: activeButton === 4 ? 'white' : '#01D37E',
                backgroundColor: activeButton === 4 ? '#01D37E' : 'white',
                height: '39px',
                borderRadius: '10px',
                border: 'none',
                padding: '9px 16px',
                gap: '10px',
                fontFamily: 'Maison Neue',
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: '#01D37E',
                  color: 'white',
                },
              }}
              onClick={() => handleButtonClick(4)}>
              {t('entrada.contenedor.legislativo')}
            </Button>
            <Button
              sx={{
                color: activeButton === 5 ? 'white' : '#01D37E',
                backgroundColor: activeButton === 5 ? '#01D37E' : 'white',
                height: '39px',
                borderRadius: '10px',
                border: 'none',
                padding: '9px 16px',
                gap: '10px',
                fontFamily: 'Maison Neue',
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: '#01D37E',
                  color: 'white',
                },
              }}
              onClick={() => handleButtonClick(5)}>
              {t('entrada.contenedor.judicial')}
            </Button>
            <Button
              sx={{
                color: activeButton === 6 ? 'white' : '#01D37E',
                backgroundColor: activeButton === 6 ? '#01D37E' : 'white',
                height: '39px',
                borderRadius: '10px',
                border: 'none',
                padding: '9px 16px',
                gap: '10px',
                fontFamily: 'Maison Neue',
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: '#01D37E',
                  color: 'white',
                },
              }}
              onClick={() => handleButtonClick(6)}>
              {t('entrada.contenedor.electoral')}
            </Button>
            <Button
              sx={{
                color: activeButton === 7 ? 'white' : '#01D37E',
                backgroundColor: activeButton === 7 ? '#01D37E' : 'white',
                height: '39px',
                borderRadius: '10px',
                border: 'none',
                padding: '9px 16px',
                gap: '10px',
                fontFamily: 'Maison Neue',
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: '#01D37E',
                  color: 'white',
                },
              }}
              onClick={() => handleButtonClick(7)}>
              {t('entrada.contenedor.ciudadano')}
            </Button>
          </ButtonGroup>
        </Stack>
      </Grid>
    </Grid>
  );
}
