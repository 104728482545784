import React, { useEffect, useState } from 'react';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';
import { useNavigate, useParams } from 'react-router-dom';
import { FormControl, FormHelperText, FormLabel, Input, Snackbar } from '@mui/joy';
import { Gazette } from '../../list/domain/gazette.model';
import http from '../../../core/infrastructure/HttpService';

export default function GazetteFormPage() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { id } = useParams();
  const [gazette, setGazette] = useState<Gazette | null>(null);

  useEffect(() => {
    async function fetchGazette() {
      setLoading(true);
      try {
        const response = await http.get(`/gazettes/${id}`);
        setGazette(response.data);
      } catch (e) {
        setErrorMessage('Ocurrió un problema al obtener el rol para editar, por favor intenta de nuevo más tarde.');
        console.log(e);
      } finally {
        setLoading(false);
      }
    }

    if (id) {
      fetchGazette();
    }
  }, [id]);

  async function submitCreateGazetteForm(formJson: any) {
    setLoading(true);
    setOpen(false);

    try {
      if (id) {
        await http.patch(`/gazettes/${gazette?.uuid}`, { ...formJson });
      } else {
        await http.post('/gazettes', { ...formJson });
      }
      setOpen(true);
    } catch (e: any) {
      if (e.response?.data.code === 'already-exists') {
        setErrorMessage('Este nombre ya está en uso.');
      } else {
        setErrorMessage('Ha ocurrido un error inesperado, por favor intenta de nuevo más tarde.');
      }

      setLoading(false);
    }
  }

  return (
    <>
      <Snackbar
        autoHideDuration={2000}
        color="success"
        variant="solid"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={() => {
          navigate('/app/roles');
        }}>
        Se ha {id ? 'actualizado' : 'creado'} el rol exitosamente. Serás redirigido en un momento.
      </Snackbar>

      <Snackbar
        autoHideDuration={2000}
        color="danger"
        variant="solid"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={errorMessage !== null}
        onClose={() => {
          setErrorMessage(null);
        }}>
        {errorMessage}
      </Snackbar>

      <Typography level="h2" component="h1" sx={{ mt: 1, mb: 2 }}>
        {id ? 'Editar' : 'Crear'} rol
      </Typography>

      <Sheet
        sx={{
          mx: 'auto',
          my: 4,
          py: 3,
          px: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          borderRadius: 'sm',
          boxShadow: 'md',
        }}>
        <form
          onSubmit={event => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            submitCreateGazetteForm(formJson);
          }}>
          <Stack direction="column" spacing={2}>
            <FormControl disabled={loading} error={errorMessage !== null}>
              <FormLabel>Nombre de la gaceta</FormLabel>
              <Input name="name" type="text" placeholder="Ingresa el nombre del rol" required />

              <FormHelperText color="danger">{errorMessage}</FormHelperText>
            </FormControl>

            <FormControl disabled={loading} error={errorMessage !== null}>
              <FormLabel>Archivo</FormLabel>
              <Input name="name" type="text" placeholder="Ingresa el nombre del rol" required />

              <FormHelperText color="danger">{errorMessage}</FormHelperText>
            </FormControl>

            <Button type="submit" disabled={loading} loading={loading}>
              {id ? 'Actualizar' : 'Crear'} rol
            </Button>
          </Stack>
        </form>
      </Sheet>
    </>
  );
}
