import axios from 'axios';
import { getAuth } from 'firebase/auth';

const http = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/manager-app`,
});

http.interceptors.request.use(
  async config => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const token = await user.getIdToken();
      config.headers.Authorization = `Bearer ${token}`;
      // TODO
      config.headers['ngrok-skip-browser-warning'] = '69420';
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

http.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 403 && error.response.data.message === '2fa-required') {
      window.location.href = '/two-factor';
    }

    return Promise.reject(error);
  },
);

export default http;
