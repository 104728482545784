import React, { ReactElement, useEffect, useState } from 'react';
import Box from '@mui/joy/Box';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import { Modal, Option, Select } from '@mui/joy';
import IconButton from '@mui/joy/IconButton';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Table from '@mui/joy/Table';
import Skeleton from '@mui/joy/Skeleton';
import Typography from '@mui/joy/Typography';
import PictureAsPdfSharpIcon from '@mui/icons-material/PictureAsPdfSharp';
import CloseIcon from '@mui/icons-material/Close';
import { useGazetteListManagementContext } from '../../application/GazetteListPageContext';
import { useTranslation } from 'react-i18next';
import http from '../../../../core/infrastructure/HttpService';
import { useNavigate } from 'react-router-dom';

interface HeadCell {
  disablePadding: boolean;
  id: keyof Gazette;
  label: string;
  numeric: boolean;
}

interface Gazette {
  uuid: string;
  gazetteNumber: number;
  type: string;
  jurisdiction?: string;
  publicationDate: Date;
  fileUrl: string;
}

interface EnhancedTableProps {
  onRequestSort: (property: keyof Gazette) => void;
  rowCount: number;
}

export function GazettesTable() {
  const { gazettes, loading, pageSize, page, setPage, setPageSize, sortOrder, orderBy, setSortOrder, setOrderBy } =
    useGazetteListManagementContext();
  const [openModal, setOpenModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const { t } = useTranslation();
  const [sortedGazettes, setSortedGazettes] = useState<Gazette[]>([]);
  const navigate = useNavigate();

  const openPdfModal = (pdfUrl: string) => {
    setPdfUrl(pdfUrl);
    setOpenModal(true);
  };

  function handlePageChange(newPage: number) {
    setPage(newPage);
  }

  function loadSkeletonRows(rows: number): ReactElement {
    const array = Array(rows).fill(null);

    return (
      <React.Fragment>
        {array.map((value, index) => {
          return (
            <tr key={`skeleton-row-${index}`}>
              <td>
                <Typography>
                  <Skeleton> Número</Skeleton>
                </Typography>
              </td>
              <td>
                <Typography>
                  <Skeleton>Extraordinaria</Skeleton>
                </Typography>
              </td>
              <td>
                <Typography>
                  <Skeleton>Venezuela</Skeleton>
                </Typography>
              </td>
              <td>
                <Typography>
                  <Skeleton>1/01/1999</Skeleton>
                </Typography>
              </td>
              <td>
                <Typography>
                  <Skeleton>No</Skeleton>
                </Typography>
              </td>
              <td>
                <Typography>
                  <Skeleton>No</Skeleton>
                </Typography>
              </td>
            </tr>
          );
        })}
      </React.Fragment>
    );
  }

  const headCells: readonly HeadCell[] = [
    {
      id: 'gazetteNumber',
      numeric: false,
      disablePadding: true,
      label: 'Número',
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: 'Tipo',
    },
    {
      id: 'jurisdiction',
      numeric: false,
      disablePadding: false,
      label: 'Jurisdicción',
    },
    {
      id: 'publicationDate',
      numeric: true,
      disablePadding: false,
      label: 'Fecha de publicación',
    },
  ];

  async function fetchOrder(orderBy: string, sortOrder: string) {
    try {
      const response = await http.get(
        `/gazettes?pageSize=${pageSize}&orderBy=${orderBy}&sortOrder=${sortOrder}&page=${page}`,
      );
      setSortedGazettes(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  function handleRequestSort(property: string) {
    if (property === orderBy) {
      if (sortOrder === 'asc') {
        setSortOrder('desc');
      } else if (sortOrder === 'desc') {
        setSortOrder('asc');
      }
    } else {
      setOrderBy(property);
      setSortOrder('asc');
    }
  }

  useEffect(() => {
    const queryParams = new URLSearchParams();
    fetchOrder(orderBy, sortOrder);
    queryParams.append('orderBy', orderBy);
    queryParams.append('sortOrder', sortOrder);
    if (page !== 1) {
      queryParams.append('page', page.toString());
      queryParams.append('pageSize', pageSize.toString());
    }
    navigate(`?${queryParams.toString()}`, { replace: true });
  }, [orderBy, sortOrder]);

  function EnhancedTableHead(props: EnhancedTableProps) {
    return (
      <thead>
        <tr>
          {headCells.map(headCell => (
            <th key={headCell.id} onClick={() => handleRequestSort(headCell.id)} style={{ cursor: 'pointer' }}>
              {headCell.label}
              {orderBy === headCell.id && <span>{sortOrder === 'desc' ? ' ↓' : ' ↑'}</span>}
            </th>
          ))}
        </tr>
      </thead>
    );
  }

  return (
    <Box>
      <Table hoverRow stripe="odd">
        <EnhancedTableHead onRequestSort={handleRequestSort} rowCount={gazettes.length} />
        <tbody>
          {loading ? loadSkeletonRows(pageSize) : null}
          {sortedGazettes.map(gazette => (
            <tr key={`gazette-row-${gazette.uuid}`}>
              <td>
                <Box display="flex" alignItems="center">
                  <IconButton onClick={() => openPdfModal(gazette.fileUrl)}>
                    <PictureAsPdfSharpIcon />
                  </IconButton>
                  <Typography>{gazette.gazetteNumber}</Typography>
                </Box>
              </td>
              <td>{gazette.type}</td>
              <td>{gazette.jurisdiction}</td>
              <td>{gazette.publicationDate.toString()}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={4}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  justifyContent: 'flex-end',
                }}>
                <FormControl orientation="horizontal" size="sm">
                  <FormLabel>{t('gacetas.gaceta_pagina')}</FormLabel>
                  <Select value={pageSize} onChange={(e, value) => setPageSize(value || 10)}>
                    <Option value={10}>10</Option>
                    <Option value={25}>25</Option>
                    <Option value={50}>50</Option>
                  </Select>
                </FormControl>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  <IconButton
                    size="sm"
                    color="neutral"
                    variant="outlined"
                    disabled={page === 1}
                    onClick={() => handlePageChange(page - 1)}
                    sx={{ bgcolor: 'background.surface' }}>
                    <KeyboardArrowLeftIcon />
                  </IconButton>

                  <Typography>
                    {t('gacetas.pagina')} {page}
                  </Typography>

                  <IconButton
                    size="sm"
                    color="neutral"
                    variant="outlined"
                    disabled={gazettes.length < pageSize}
                    onClick={() => handlePageChange(page + 1)}
                    sx={{ bgcolor: 'background.surface' }}>
                    <KeyboardArrowRightIcon />
                  </IconButton>
                </Box>
              </Box>
            </td>
          </tr>
        </tfoot>
      </Table>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          style={{
            position: 'fixed',
            top: '50%',
            left: '60%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: '800px',
            maxHeight: '80%',
            height: 'auto',
            zIndex: 1100,
            overflowY: 'auto',
            border: 'none',
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          }}>
          <IconButton
            onClick={() => setOpenModal(false)}
            style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 1001 }}>
            <CloseIcon />
          </IconButton>
          <Typography> PDF Viewer</Typography>
          <div style={{ width: '100%', paddingBottom: '56.25%', position: 'relative' }}>
            <iframe
              src={pdfUrl}
              style={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                border: 'none',
              }}
              title="PDF Viewer"
            />
          </div>
        </Box>
      </Modal>
    </Box>
  );
}
